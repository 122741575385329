import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import styled from 'styled-components'
import { StaffListQuery } from '../../../generated/types'
import { colors } from '../../../styles/variables'
import { ProfileName } from '../../ProfileImage'
import SectionHeading from '../../SectionHeading'
import Profile from './Profile'
import OtherProfiles from './OtherProfiles'

const query = graphql`
  query StaffList {
    allMarkdownRemark(limit: 1000, sort: { fields: frontmatter___priority }) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          html
          frontmatter {
            photo
            title
          }
        }
      }
    }
  }
`

const Container = styled.div`
  & > .heading-background {
    background-color: ${colors.main};
  }
`

const Profiles: FC = () => {
  const { allMarkdownRemark } = useStaticQuery<StaffListQuery>(query)

  return (
    <Container>
      <div className="heading-background">
        <SectionHeading text="Náš Tým" />
      </div>

      {allMarkdownRemark.edges.map(({ node }, index) => {
        const { id, excerpt, frontmatter } = node

        if (!frontmatter) {
          return null
        }

        const { photo, title } = frontmatter
        return <Profile key={id} index={index} photo={photo as ProfileName} excerpt={excerpt} title={title || ''} />
      })}

      <OtherProfiles />
    </Container>
  )
}

export default Profiles
