import { Link } from 'gatsby'
import React, { useState, FC } from 'react'
import styled, { css } from 'styled-components'
import { Maybe } from '../../../generated/types'
import { colors, fontSize, spacing } from '../../../styles/variables'
import ProfileImage, { ProfileName } from '../../ProfileImage'

interface StyledProps {
  index: number
}

const Container = styled.div<StyledProps>`
  min-height: 100vh;

  padding-top: ${spacing.size08};
  padding-bottom: ${spacing.size08};

  display: flex;
  flex-wrap: wrap;

  ${({ index }) =>
    index % 2 === 0
      ? css`
          background-color: ${colors.main};
          flex-direction: row;
        `
      : css`
          background-color: ${colors.white};
          flex-direction: row-reverse;
        `};

  & > .flex-child {
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    & > .image {
      @media screen and (max-width: ${spacing.size14}) {
        width: 100%;
        min-width: 250px;

        margin-left: ${spacing.size05};
        margin-right: ${spacing.size05};

        &::before {
          display: none;
        }
      }
    }

    & > .text-wrapper {
      width: ${spacing.size15};

      padding-top: ${spacing.size10};
      padding-bottom: ${spacing.size10};
      padding-left: ${spacing.size07};
      padding-right: ${spacing.size07};

      & > h4 {
        font-size: ${fontSize.size06};
        margin-bottom: ${spacing.size05};
      }

      & > p {
        line-height: 1.4;
        margin-bottom: ${spacing.size04};
        font-size: ${fontSize.size04};
      }

      @media screen and (max-width: ${spacing.size15}) {
        width: 80vw;

        padding-top: ${spacing.size08};
        padding-bottom: ${spacing.size08};
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`

interface Props extends StyledProps {
  title: string
  photo?: Maybe<ProfileName>
  excerpt?: Maybe<string>
}

const Profile: FC<Props> = (props) => {
  const { index, excerpt, photo, title } = props
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <Container index={index}>
      <div className="flex-child">{photo && <ProfileImage className="image" index={index} name={photo} />}</div>
      <div className="flex-child">
        <div className="text-wrapper" onClick={() => setIsCollapsed(!isCollapsed)}>
          <h4>{title}</h4>
          <p>{excerpt}</p>
          <Link to={`staff/${photo}`}>Více</Link>
        </div>
      </div>
    </Container>
  )
}

export default Profile
