import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { assertUnreachable } from '../utils'

export enum IconName {
  Facebook = 'facebook',
  Instagram = 'instagram',
}

const mapIconNameToIcon = (iconName: IconName): IconDefinition => {
  switch (iconName) {
    case IconName.Facebook:
      return faFacebookSquare
    case IconName.Instagram:
      return faInstagramSquare
    default:
      return assertUnreachable(iconName)
  }
}

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  iconName: IconName
}

const Icon: FC<Props> = ({ iconName, ...props }) => {
  const icon = mapIconNameToIcon(iconName)
  return <FontAwesomeIcon icon={icon} {...props} />
}

export default Icon
