import React, { FC } from 'react'
import styled from 'styled-components'
import { colors, fontSize, spacing } from '../../styles/variables'
import Anchor from '../Anchor'
import HeadingTwo from '../HeadingTwo'
import Icon, { IconName } from '../Icon'
import SimpleCenter from '../layout/SimpleCenter'
import SimplePage from '../layout/SimplePage'
import SimpleRow from '../layout/SimpleRow'
import MapBox from '../MapBox'

const Header = styled(SimpleCenter)`
  padding-top: ${spacing.size09};
  margin-bottom: ${spacing.size10};
`

const Text = styled.div`
  margin-right: ${spacing.size10};
  margin-bottom: ${spacing.size06};

  color: ${colors.white};
  font-size: ${fontSize.size06};
  line-height: 1.4;

  & .block {
    margin-bottom: ${spacing.size05};

    & .margin-left {
      margin-left: 8px;
    }
  }

  & .heading {
    font-weight: bold;
    font-size: ${fontSize.size07};

    @media (max-width: 650px) {
      font-size: ${fontSize.size06};
    }
  }

  & .name {
    font-size: ${fontSize.size07};

    @media (max-width: 650px) {
      font-size: ${fontSize.size06};
    }
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 650px) {
    font-size: ${fontSize.size05};
  }
`

const MapWrapper = styled(MapBox)`
  height: 450px;
  width: 816px;
  margin-bottom: ${spacing.size06};

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 30px;

  & .mapboxgl-canvas {
    border-radius: 30px;
  }

  & .mapboxgl-control-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 1024px) {
    height: 450px;
    width: 100%;
    margin-left: ${spacing.size04};
    margin-right: ${spacing.size04};
  }
`

const Row = styled(SimpleRow)`
  align-items: flex-start;
  flex-wrap: wrap;
`

const Footer = styled(SimpleCenter)`
  padding-bottom: ${spacing.size07};

  color: ${colors.white};
  font-size: ${fontSize.size06};
  line-height: 1.4;

  @media (max-width: 650px) {
    font-size: ${fontSize.size05};
  }
`

const Contacts: FC = () => (
  <SimplePage style={{ backgroundColor: colors.main }}>
    <Header>
      <HeadingTwo>Kontakty</HeadingTwo>
    </Header>
    <Row>
      <Text>
        <div className="block">
          <p className="heading">MyFyzio - fyzioterapie</p>
          <p>Koterovská 17, Plzeň 326 00</p>
          <p>
            <Anchor href="https://www.facebook.com/MyFyzioPlzen/">
              <Icon iconName={IconName.Facebook} size="lg" />
            </Anchor>
            <Anchor className="margin-left" href="https://www.instagram.com/myfyzio/">
              <Icon iconName={IconName.Instagram} size="lg" />
            </Anchor>
          </p>
        </div>

        <div className="block">
          <p className="name">Bc. Marie Šafrhansová</p>
          <p>safrhansova@myfyzio.cz</p>
          <p>+420 777 039 384</p>
          <p>IČO: 07066848</p>
        </div>

        <div className="block">
          <p className="name">Bc. Adéla Dankovičová</p>
          <p>dankovicova@myfyzio.cz</p>
          <p>+420 723 800 299</p>
          <p>IČO: 08559163</p>
        </div>

        <div className="block">
          <p className="name">Bc. Jana Kubečková</p>
          <p>kubeckova@myfyzio.cz</p>
          <p>+420 606 570 598</p>
          <p>IČO: 08254711</p>
        </div>

        <div className="block">
          <p className="name">Bc. Andrea Königsmarková</p>
          <p>konigsmarkova@myfyzio.cz</p>
          <p>+420 606 620 190</p>
          <p>IČO: 14038846</p>
        </div>

        <div className="block">
          <p className="name">Bc. Jan Řehoř</p>
          <p>rehor@myfyzio.cz</p>
          <p>+420 601 593 727</p>
          <p>IČO: 19057351</p>
        </div>
      </Text>

      <MapWrapper cords={[13.3895856, 49.7411658]} />
    </Row>

    <Footer>
      <p>Otevírací doba je po telefonické domluvě.</p>
    </Footer>
  </SimplePage>
)

export default Contacts
