import React, { CSSProperties, FC, ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  style?: CSSProperties | undefined
  className?: string | undefined
}

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SimpleRow: FC<Props> = (props) => {
  const { className, children, style } = props

  return (
    <Row style={style} className={className}>
      {children}
    </Row>
  )
}

export default SimpleRow
