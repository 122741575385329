import React, { FC } from 'react'
import styled from 'styled-components'
import { spacing, fontSize } from '../../../styles/variables'

interface Props {
  title: string
  subtitle?: string
  price: number
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  max-width: 700px;

  padding-left: ${spacing.size05};
  padding-right: ${spacing.size05};
  margin-left: auto;
  margin-right: auto;

  & > .mobile-subtitle {
    display: none;
  }

  & > .info {
    max-width: 500px;

    & > .title {
      margin-bottom: ${spacing.size03};

      font-size: ${fontSize.size05};
      line-height: 1.3;
    }
  }

  & > .price {
    flex-shrink: 0;

    margin-top: ${spacing.size03};
    margin-bottom: ${spacing.size03};

    font-size: ${fontSize.size05};
  }

  @media screen and (max-width: ${spacing.size14}) {
    flex-direction: column;

    & > .info {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;

      & > .title {
        text-align: center;
        margin-bottom: 0;
      }

      & > .subtitle {
        display: none;
      }
    }

    & > .mobile-subtitle {
      display: block;
    }
  }
`

export const PriceLine: FC<Props> = (props) => {
  const { title, subtitle, price } = props

  return (
    <Container>
      <div className="info">
        <h4 className="title">{title}</h4>
        {subtitle && <p className="subtitle">{subtitle}</p>}
      </div>

      <p className="price">{price},- Kč</p>
      {subtitle && <p className="mobile-subtitle">{subtitle}</p>}
    </Container>
  )
}
