import React, { FC } from 'react'
import Layout from '../components/layout/Layout'
import Contacts from '../components/homepage/Contacts'
import Hero from '../components/homepage/Hero'
import Profiles from '../components/homepage/profiles/Profiles'
import Gallery from '../components/homepage/Gallery'
import Pricing from '../components/homepage/pricing/Pricing'
import { Helmet } from 'react-helmet'

const IndexPage: FC = () => (
  <>
    <Helmet>
      <title>My Fyzio - Fyzioterapie Plzeň</title>
      <meta name="description" content="Webová vizitka pro My Fyzio - Fyzioterapeutická ordinace v Plzni"></meta>
      <meta name="robots" content="index, follow"></meta>
      <link href="URL" rel="canonical"></link>
    </Helmet>
    <Layout>
      <Hero />
      <Profiles />
      <Pricing />
      <Contacts />
      <Gallery />
    </Layout>
  </>
)

export default IndexPage
