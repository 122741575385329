import React, { FC } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/variables'
import SectionHeading from '../../SectionHeading'
import { PriceLine } from './PriceLine'

const Container = styled.div`
  min-height: 100vh;

  background-color: ${colors.white};

  & > .section {
    margin-top: ${spacing.size06};
    margin-bottom: ${spacing.size04};

    & > .spacer {
      height: ${spacing.size08};
    }
  }

  & > .text {
    max-width: 700px;

    padding-left: ${spacing.size05};
    padding-right: ${spacing.size05};
    margin-bottom: ${spacing.size07};
    margin-left: auto;
    margin-right: auto;

    line-height: 1.4;
  }
`

const Pricing: FC = () => {
  return (
    <Container>
      <SectionHeading text="Ceník" color={colors.main} />

      <div className="section">
        <PriceLine title="Vstupní vyšetření + Terapie" subtitle="cca 90 min" price={1500} />

        <div className="spacer" />

        <PriceLine title="Terapie" subtitle="cca 60 min" price={1000} />

        <div className="spacer" />

        <PriceLine
          title="Ošetření přístrojem BTL 4000 premium - laser, ultrazvuk, elektroterapie"
          subtitle="cca 30min"
          price={500}
        />
      </div>

      <p className="text">
        V rámci terapie je ošetření zahrnuto v ceně, mimo terapii se cena odvíjí podle doby ošetření.
      </p>

      <div className="section">
        <PriceLine title="Tejpování" subtitle="+ 1,- Kč za cm" price={300} />
      </div>

      <p className="text">
        Cena tejpování a materiálu je zahrnuta do ceny terapie. V případě navštívení ambulance pouze za účelem
        zatejpování platí výše zmíněná cena.
      </p>

      <p className="text">
        Uvedené ceny jsou pouze orientační. Výsledná cena poskytnutých zdravotních služeb se může individuálně lišit.
      </p>

      <p className="text">
        Při zrušení terapie méně než 24h dopředu bude účtován storno poplatek ve výši 50% ceny terapie.
      </p>
    </Container>
  )
}

export default Pricing
