import React, { ReactNode, CSSProperties, FC } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  style?: CSSProperties | undefined
}

const Page = styled.section`
  min-height: 100vh;
`

const SimplePage: FC<Props> = (props) => {
  return <Page style={props.style}>{props.children}</Page>
}

export default SimplePage
