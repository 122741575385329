import React, { FC } from 'react'
import styled from 'styled-components'
import Logo from '../../../assets/logo.svg'
import { fontSize } from '../../styles/variables'
import HeadingOne from '../HeadingOne'
import SimpleCenter from '../layout/SimpleCenter'
import SimpleColumn from '../layout/SimpleColumn'

const StyledLogo = styled(Logo)`
  width: 600px;
  height: 600px;

  @media (max-width: 650px) {
    width: 320px;
    height: 320px;
  }
`

const Heading = styled(HeadingOne)`
  @media (max-width: 650px) {
    font-size: ${fontSize.size07};
  }
`

const Hero: FC = () => {
  return (
    <SimpleCenter style={{ height: '100vh' }}>
      <SimpleColumn>
        <StyledLogo />
        <Heading>Fyzioterapie Plzeň</Heading>
      </SimpleColumn>
    </SimpleCenter>
  )
}

export default Hero
