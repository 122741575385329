import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.span`
  & > a {
    color: inherit;
  }
`

type Props = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

const Anchor: FC<Props> = ({ children, ...anchorProps }) => {
  return (
    <Container>
      <a {...anchorProps}>{children}</a>
    </Container>
  )
}

export default Anchor
