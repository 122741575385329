import React, { FC } from 'react'
import { spacing, colors } from '../../../styles/variables'
import styled from 'styled-components'
import Anchor from '../../Anchor'

const Container = styled.div`
  padding-top: ${spacing.size08};
  padding-bottom: ${spacing.size08};
  padding-left: ${spacing.size08};
  padding-right: ${spacing.size08};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.main};

  & > p {
    line-height: 1.4;
  }

  @media screen and (max-width: ${spacing.size12}) {
    padding-left: ${spacing.size05};
    padding-right: ${spacing.size05};
  }
`

const OtherProfiles: FC = () => {
  return (
    <Container>
      <p>
        Dále spolupracujeme s dětskou fyzioterapeutkou Pavlou Luhanovou, DiS., více info na{' '}
        <Anchor href="http://www.fyziovpohybu.cz">www.fyziovpohybu.cz</Anchor>
      </p>
    </Container>
  )
}

export default OtherProfiles
