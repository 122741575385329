// @ts-ignore
import mapboxgl from '!mapbox-gl'
import type { MapboxOptions } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { spacing } from '../styles/variables'

mapboxgl.accessToken = 'pk.eyJ1IjoicGFuemVsdmEiLCJhIjoiY2p3NTFsZ3JlMHJ1ODQzb21kaGYwY2I0cyJ9.mD1VqFFlW629T9zXRv0ZNQ'

const MapWrapper = styled.div`
  & .mapboxgl-ctrl-bottom-right {
    right: ${spacing.size05};
  }

  & .mapboxgl-ctrl-bottom-left {
    left: ${spacing.size05};
  }
`

type Props = {
  cords: [number, number]
  className?: string | undefined
}

const MapBox: FC<Props> = (props: Props) => {
  const { cords, className } = props

  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!container || !container.current) {
      return
    }

    const options: MapboxOptions = {
      center: cords,
      container: container.current,
      style: 'mapbox://styles/panzelva/cjz8q9llg03va1cqi608fxu3d',
      zoom: 15,
    }

    const map = new mapboxgl.Map(options)

    map.on('load', () => {
      map.loadImage('https://i.imgur.com/MK4NUzI.png', (error: unknown, image: any) => {
        if (error) {
          throw error
        }

        map.addImage('custom-marker', image)

        map.addLayer({
          id: 'markers',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: cords,
                  },
                },
              ],
            },
          },
          layout: {
            'icon-image': 'custom-marker',
          },
        })
      })
    })
  }, [])

  return <MapWrapper ref={container} className={className} />
}

export default MapBox
