import React, { ReactNode, CSSProperties, FC } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  style?: CSSProperties | undefined
  className?: string | undefined
}

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SimpleCenter: FC<Props> = (props) => {
  const { className, style, children } = props

  return (
    <Center className={className} style={style}>
      {children}
    </Center>
  )
}

export default SimpleCenter
