import styled from 'styled-components'
import { colors } from '../styles/variables'

interface Props {
  color?: string | undefined
  size?: number | undefined
}

const HeadingOne = styled.h1<Props>`
  font-size: ${(props) => props.size || 60}px;
  color: ${(props) => props.color || colors.main};
`

export default HeadingOne
