import React, { FC } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../styles/variables'
import HeadingTwo from './HeadingTwo'

const Container = styled.header`
  padding-top: ${spacing.size09};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${colors.white};
`

interface Props {
  text: string
  color?: string | undefined
}

const SectionHeading: FC<Props> = (props) => {
  const { text, color } = props

  return (
    <Container>
      <HeadingTwo color={color}>{text}</HeadingTwo>
    </Container>
  )
}

export default SectionHeading
