import Img, { FluidObject } from 'gatsby-image'
import React, { FC, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import styled from 'styled-components'
import { spacing } from '../styles/variables'

const Container = styled.div`
  & > .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;

    & > div {
      margin: ${spacing.size04};
      cursor: pointer;

      & .gallery-image {
        width: ${spacing.size12};
        height: ${spacing.size12};
        object-fit: cover;
      }
    }
  }
`

export interface RawImage {
  childImageSharp: {
    fluid: FluidObject
  }
}

interface Props {
  images: RawImage[]
}

const LightBoxGallery: FC<Props> = (props) => {
  const { images: rawImages } = props
  const images = rawImages.map((image) => {
    const { src: source } = image.childImageSharp.fluid
    return { source }
  })

  const [isOpen, setIsOpen] = useState(false)
  const [currentIndex, setCurrentImage] = useState(0)

  const openLightbox = (index: number) => {
    setCurrentImage(index)
    setIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setIsOpen(false)
  }

  return (
    <Container>
      <ModalGateway>
        {isOpen ? (
          <Modal onClose={() => closeLightbox()}>
            <Carousel views={images} currentIndex={currentIndex} />
          </Modal>
        ) : null}
      </ModalGateway>
      <div className="gallery">
        {rawImages.map((image, index) => (
          <div key={index} onClick={() => openLightbox(index)}>
            <Img className="gallery-image" fluid={image.childImageSharp.fluid} />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default LightBoxGallery
