import React, { CSSProperties, FC, ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  style?: CSSProperties | undefined
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SimpleColumn: FC<Props> = (props) => {
  return <Column style={props.style}>{props.children}</Column>
}

export default SimpleColumn
